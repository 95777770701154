import styled, { css } from 'styled-components';
import { Wrapper as Button } from '../../form/button/button.styled';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2rem 2rem 2rem;

        @media ${theme.breakpoints.md.min} {
            padding: 1.6rem 3.2rem 3.2rem 3.2rem;
        }

        ${Button}:only-child {
            margin-left: auto;
        }
    `}
`;
