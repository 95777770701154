import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        padding: 2rem 2rem 1rem 2rem;
        gap: ${theme.gap.medium};

        @media ${theme.breakpoints.md.min} {
            padding: 3.2rem 3.2rem 1.6rem 3.2rem;
        }
    `}
`;

export const Main = styled.div`
    ${() => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
    `}
`;

export const Title = styled.div`
    ${({ theme }) => css`
        font-size: ${theme.headline.size.h3.fontSize};
        line-height: ${theme.headline.size.h3.lineHeight};
        color: ${theme.palette.common.text};
        font-weight: 700;
    `}
`;

export const Close = styled.div.withConfig({
    shouldForwardProp: (prop) => !['locked'].includes(prop),
})<{
    locked: boolean;
}>`
    ${({ theme, locked }) => css`
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            color: ${theme.modal.close.base.color};
            height: 2rem;
            transition: color ${theme.base.transition};
            width: 2rem;
        }

        &:hover {
            i {
                color: ${theme.modal.close.hover.color};
            }
        }

        ${locked &&
        css`
            i {
                color: ${theme.palette.color.white.main};
            }

            &:hover {
                i {
                    color: ${theme.palette.color.white.main};
                }
            }
        `}
    `}
`;
