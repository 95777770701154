import React from 'react';
import { Wrapper } from './modal-footer.styled';

type ModalFooterType = {
    children: React.ReactNode;
};

export const ModalFooter: React.FC<ModalFooterType> = ({ children }: ModalFooterType) => {
    return children ? <Wrapper>{children}</Wrapper> : null;
};
