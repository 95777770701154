import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 2rem;

        &:empty {
            display: none;
        }

        @media ${theme.breakpoints.md.min} {
            padding-top: 3.2rem;
        }
    `}
`;
