import styled, { css } from 'styled-components';
import { ModalAlignEnum } from '@app/common';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['locked', 'width', 'align'].includes(prop),
})<{
    locked: boolean;
    width?: number;
    align: ModalAlignEnum;
}>`
    ${({ theme, locked, width, align }) => css`
        background-color: ${theme.modal.window.background};
        max-width: ${width ? `${width}rem` : '56rem'};
        position: relative;
        width: 100%;
        z-index: 1200;
        max-height: 100%;
        display: inline-flex;
        border-radius: ${theme.radius.medium};

        ${(locked || align === 'center') &&
        css`
            text-align: center;
        `}
    `}
`;

export const Inner = styled.div`
    ${() => css`
        display: flex;
        flex: 1;
        flex-direction: column;
    `}
`;
