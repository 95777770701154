import styled, { css } from 'styled-components';
import { getOpacity } from '../../../../theme/utils/get-rgb';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        flex: 1;
        position: relative;
        display: flex;
        overflow: hidden;

        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 2rem;
            z-index: 200;

            @media ${theme.breakpoints.md.min} {
                height: 3.2rem;
            }
        }

        &:before {
            background: linear-gradient(
                180deg,
                ${getOpacity(theme.modal.window.background, 1)} 0%,
                ${getOpacity(theme.modal.window.background, 0)} 100%
            );
            top: 0;
        }

        &:after {
            background: linear-gradient(
                180deg,
                ${getOpacity(theme.modal.window.background, 0)} 0%,
                ${getOpacity(theme.modal.window.background, 1)} 100%
            );
            bottom: 0;
            border-radius: 0 0 ${theme.radius.medium} ${theme.radius.medium};
        }
    `}
`;

export const Inner = styled.div`
    ${({ theme }) => css`
        padding: 2rem 2rem;
        flex: 1;
        overflow: auto;
        position: relative;
        z-index: 100;

        @media ${theme.breakpoints.md.min} {
            padding: 3.2rem 3.2rem;
        }
    `}
`;
