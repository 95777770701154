import React from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useRouter } from 'next/router';
import { useRootStore } from '../../../../providers/root-store-provider';
import { ImpersonationMobxDto } from '../../../../models/mobx/dtos/impersonation/impersonation-mobx-dto';
import { routes } from '../../../../config/routes';
import { FormItem } from '../../../app/form/item/item';
import { TextField } from '../../../app/form/textfield/textfield';
import { Button } from '../../../app/form/button/button';
import { BaseDialog } from '../../../app/dialogs/base-dialog';

export const ImpersonationDialog: React.FC = observer(() => {
    const { t } = useTranslation();
    const rootStore = useRootStore();
    const router = useRouter();
    const { impersonationDialogStore } = rootStore.authStore;

    const initialValues = new ImpersonationMobxDto();
    const validationSchema = yup.object({
        email: yup
            .string()
            .required(t('layout.forms.common.emailValidationRequired'))
            .email(t('layout.forms.common.emailValidationValid')),
    });

    const onSubmit = async (values: ImpersonationMobxDto) => {
        try {
            await rootStore.authStore.impersonation(values.email);
            await router.push(routes.app.index);
            impersonationDialogStore.closeDialog();
        } catch (e) {
            impersonationDialogStore.closeDialog();
            // eslint-disable-next-line no-console
            console.error(e);
            rootStore.alertStore.setErrorMessageByStatus(e.message);
        }
    };

    return (
        <>
            <BaseDialog
                opened={impersonationDialogStore.activeModal === 'impersonate'}
                close={() => impersonationDialogStore.closeDialog()}
                title={t('dialogs.common.impersonation')}
                content={
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                        {({ values, handleChange, touched, handleBlur, errors, handleSubmit }) => (
                            <Form id={'signup-form'}>
                                <FormItem>
                                    <TextField
                                        fullWidth
                                        label={t('layout.forms.common.emailField')}
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.email && errors.email ? errors.email : ''}
                                        error={Boolean(touched.email && errors.email)}
                                    />
                                </FormItem>
                                <FormItem>
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleSubmit();
                                        }}
                                        block
                                        variant="primary"
                                        loading={impersonationDialogStore.loading}
                                    >
                                        {t('dialogs.common.impersonate')}
                                    </Button>
                                </FormItem>
                            </Form>
                        )}
                    </Formik>
                }
            />
        </>
    );
});
