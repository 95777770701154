import React from 'react';
import { Wrapper, Close, Title, Main } from './modal-header.styled';
import { IconClose } from '../../icons/close';

type ModalHeaderType = {
    children?: React.ReactNode;
    title?: string | React.ReactNode;
    onClose?: () => void;
    locked?: boolean;
    showCloseButton?: boolean;
};

export const ModalHeader: React.FC<ModalHeaderType> = ({
    title,
    children,
    onClose,
    locked = false,
    showCloseButton = true,
}: ModalHeaderType) => {
    return (
        <Wrapper>
            <Main>
                <Title>{title}</Title>
                {showCloseButton && (
                    <Close locked={locked} onClick={onClose}>
                        <IconClose />
                    </Close>
                )}
            </Main>
            {children || null}
        </Wrapper>
    );
};
