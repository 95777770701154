import styled, { css } from 'styled-components';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['show'].includes(prop),
})<{
    show?: boolean;
}>`
    ${({ theme, show }) => css`
        bottom: 0;
        left: 0;
        opacity: 0;
        position: fixed;
        right: 0;
        top: 0;
        padding: 1rem;
        transition: all ${theme.base.transition};
        visibility: hidden;
        z-index: 1000;
        backdrop-filter: blur(0.5rem);
        align-items: center;
        display: flex;
        justify-content: center;

        ${show &&
        css`
            opacity: 1;
            visibility: visible;
        `}

        @media ${theme.breakpoints.md.min} {
            padding: 2rem;
        }
    `}
`;

export const Main = styled.div`
    ${() => css`
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
    `}
`;
