import React from 'react';
import { observer } from 'mobx-react-lite';
import { SuccessAlert } from '../success-alert';
import { ErrorAlert } from '../error-alert';
import { WarningAlert } from '../warning-alert';
import { Wrapper } from './alerts.styled';
import { useRootStore } from '../../../../providers/root-store-provider';
import { AlertPlacementEnum } from '../../../../models/enums/alert-placement-enum';

interface AlertsPropsInterface {
    email?: string;
}

export const ModalAlerts: React.FC<AlertsPropsInterface> = observer(({ email }: AlertsPropsInterface) => {
    const { alertStore } = useRootStore();

    if (alertStore.alertPlacement !== AlertPlacementEnum.Modal) {
        return null;
    }

    return (
        <Wrapper>
            <SuccessAlert />
            <ErrorAlert email={email} />
            <WarningAlert />
        </Wrapper>
    );
});
