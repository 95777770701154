import React from 'react';
import { Wrapper } from './modal-loading.styled';
import { Spinner } from '../../spinner/spinner';
import { Loader } from '../../loading/loading.styled';

type ModalLoadingType = {
    loading?: boolean;
};

export const ModalLoading: React.FC<ModalLoadingType> = ({ loading = false }: ModalLoadingType) => {
    return loading ? (
        <Wrapper>
            <Loader active={true}>
                <Spinner type={'default'} />
            </Loader>
        </Wrapper>
    ) : null;
};
