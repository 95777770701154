import React from 'react';
import { Wrapper, Inner } from './modal-content.styled';
import { ModalAlerts } from '../../alerts/modal/alerts';

type ModalContentType = {
    children: React.ReactNode;
};

export const ModalContent: React.FC<ModalContentType> = ({ children }: ModalContentType) => {
    return (
        <Wrapper>
            <Inner>
                <ModalAlerts />
                {children}
            </Inner>
        </Wrapper>
    );
};
