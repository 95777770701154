import React from 'react';
import { observer } from 'mobx-react-lite';
import { ModalAlignEnum } from '@app/common';
import { Modal } from '../modal/modal';

export interface BaseDialogInterface {
    opened: boolean;
    close: () => void;
    title?: string | React.ReactNode;
    header?: React.ReactNode;
    content: React.ReactNode;
    action?: React.ReactNode;
    loading?: boolean;
    locked?: boolean;
    width?: number;
    align?: ModalAlignEnum;
    showCloseButton?: boolean;
}

export const BaseDialog: React.FC<BaseDialogInterface> = observer(
    ({
        width,
        opened,
        align = ModalAlignEnum.Left,
        close,
        title,
        header,
        content,
        showCloseButton = true,
        action,
        loading,
        locked,
    }) => {
        return (
            <Modal
                show={opened}
                title={title}
                onClose={close}
                header={header}
                footer={action}
                loading={loading}
                locked={locked}
                width={width}
                align={align}
                showCloseButton={showCloseButton}
            >
                {content}
            </Modal>
        );
    },
);
