import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconChartBarFilled: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <g>
            <path
                d="M1.25,17h3.5A.25.25,0,0,0,5,16.75v-2a1,1,0,0,0-1-1H2a1,1,0,0,0-1,1v2A.25.25,0,0,0,1.25,17Z"
                fill="currentColor"
            />
            <path
                d="M8,8.75a1,1,0,0,0-1,1v7a.25.25,0,0,0,.25.25h3.5a.25.25,0,0,0,.25-.25v-7a1,1,0,0,0-1-1Z"
                fill="currentColor"
            />
            <path
                d="M14,10.75a1,1,0,0,0-1,1v5a.25.25,0,0,0,.25.25h3.5a.25.25,0,0,0,.25-.25v-5a1,1,0,0,0-1-1Z"
                fill="currentColor"
            />
            <path
                d="M19.25,17h3.5a.25.25,0,0,0,.25-.25v-12a1,1,0,0,0-1-1H20a1,1,0,0,0-1,1v12A.25.25,0,0,0,19.25,17Z"
                fill="currentColor"
            />
            <path
                d="M1,20.25H23a1,1,0,0,0,1-1,1,1,0,0,0-.56-.9,1.05,1.05,0,0,0-.44-.1H1a1.05,1.05,0,0,0-.44.1,1,1,0,0,0-.56.9A1,1,0,0,0,1,20.25Z"
                fill="currentColor"
            />
        </g>
    </Icon>
);
