import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconInvestment: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <g>
            <line
                x1="0.75"
                y1="23.25"
                x2="23.25"
                y2="23.25"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5px"
            />
            <path
                d="M6,16.75H3a.76.76,0,0,0-.75.75v5.75h4.5V17.5A.76.76,0,0,0,6,16.75Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5px"
            />
            <path
                d="M13.5,8.75h-3a.76.76,0,0,0-.75.75V23.25h4.5V9.5A.76.76,0,0,0,13.5,8.75Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5px"
            />
            <path
                d="M21,13.75H18a.76.76,0,0,0-.75.75v8.75h4.5V14.5A.76.76,0,0,0,21,13.75Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5px"
            />
            <circle
                cx="2.25"
                cy="10.5"
                r="1.5"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5px"
            />
            <circle
                cx="9"
                cy="2.25"
                r="1.5"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5px"
            />
            <circle
                cx="21.75"
                cy="6"
                r="1.5"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5px"
            />
            <line
                x1="3.2"
                y1="9.34"
                x2="8.05"
                y2="3.41"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5px"
            />
            <line
                x1="20.31"
                y1="5.58"
                x2="10.44"
                y2="2.67"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5px"
            />
        </g>
    </Icon>
);
