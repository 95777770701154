import React from 'react';
import { ModalAlignEnum } from '@app/common';
import { Wrapper, Inner } from './modal-window.styled';

type ModalWindowType = {
    children: Array<React.ReactNode> | React.ReactNode;
    locked: boolean;
    width?: number;
    align: ModalAlignEnum;
};

export const ModalWindow: React.FC<ModalWindowType> = ({ children, locked, width, align }: ModalWindowType) => {
    return (
        <Wrapper align={align} locked={locked} width={width}>
            <Inner>{children}</Inner>
        </Wrapper>
    );
};
