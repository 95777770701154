import React from 'react';
import { ModalAlignEnum } from '@app/common';
import { Wrapper, Main } from './modal.styled';
import { useEscapeClose } from '../../../hooks/use-escape-close';
import { ModalWindow } from './window/modal-window';
import { ModalHeader } from './header/modal-header';
import { ModalContent } from './content/modal-content';
import { ModalFooter } from './footer/modal-footer';
import { ModalLoading } from './loading/modal-loading';
import { ModalOverlay } from './overlay/modal-overlay';

type ModalType = {
    children: React.ReactNode;
    header?: React.ReactNode;
    show?: boolean;
    title?: string | React.ReactNode;
    footer?: React.ReactNode;
    onClose?: () => void;
    loading?: boolean;
    locked?: boolean;
    width?: number;
    align?: ModalAlignEnum;
    showCloseButton?: boolean;
};

export const Modal: React.FC<ModalType> = ({
    show = false,
    header,
    title,
    children,
    onClose,
    footer,
    loading = false,
    locked = false,
    width,
    align = ModalAlignEnum.Left,
    showCloseButton = true,
}: ModalType) => {
    useEscapeClose(show, onClose);

    return show ? (
        <Wrapper show={show}>
            <ModalWindow align={align} locked={locked} width={width}>
                <ModalHeader title={title} showCloseButton={showCloseButton} locked={locked} onClose={onClose}>
                    {header || null}
                </ModalHeader>

                <Main>
                    <ModalContent>{children}</ModalContent>
                    <ModalFooter>{footer}</ModalFooter>
                    <ModalLoading loading={loading} />
                </Main>
            </ModalWindow>
            <ModalOverlay />
        </Wrapper>
    ) : null;
};
