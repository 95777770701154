import styled, { css } from 'styled-components';
import { getOpacity } from '../../../../theme/utils/get-rgb';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${getOpacity(theme.palette.background.body, 0.4)};
        backdrop-filter: blur(0.2rem);
    `}
`;
